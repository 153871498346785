import PageContainer from '../../components/Charge/PageContainer'
import { ReactComponent as Logo } from '../../images/logo.svg'
import { Button, Checkbox, Typography } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import OnboardingSteps from '../../components/OnboardingSteps'
import { useFetch } from '../../hooks/requests'

const { Title } = Typography

function Header() {
  return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Logo />
    <OnboardingSteps current={1} />
  </div>
}

function Content({ primaryAction = () => null }) {
  const [request] = useFetch()
  const [agreed, setAgreed] = useState(false)
  const frameRef = useRef()
  const [scrolledDown, setScrolledDown] = useState(false)

  const handleScroll = useCallback(() => {
    const contentWindow = frameRef.current.contentWindow
    const scrollY = contentWindow.scrollY
    const windowHeight = contentWindow.innerHeight
    const bodyHeight = contentWindow.document.documentElement.clientHeight

    console.log({
      scrollY,
      windowHeight,
      bodyHeight,
      scrolledYPlusHeight: scrollY + windowHeight,
      bodyHeightMinus50: bodyHeight - 50,
    })

    if (!scrolledDown && (scrollY + windowHeight) > (bodyHeight - 50)) {
      setScrolledDown(true)
    }
  }, [scrolledDown])

  const handleLoad = useCallback(() => {
    console.log('iframe loaded')
    const contentWindow = frameRef.current.contentWindow
    contentWindow.addEventListener('scroll', handleScroll)
  }, [handleScroll])

  useEffect(() => {
    const contentWindow = frameRef.current.contentWindow
    console.log('frameRef.current', frameRef.current)

    request(`default/tos?url=${process.env.REACT_APP_TOS_PATH}${process.env.REACT_APP_TOS_VERSION}.html`)
      .then(data => {
        frameRef.current.contentDocument.write(data.content)
        handleLoad()
      })
    window.gtag('event', 'View', {
      event_category: 'Page',
      event_label: '04_agreement_ga',
    })

    // contentWindow.addEventListener('load', handleLoad)
    return () => {
      contentWindow.removeEventListener('scroll', handleScroll)
      // contentWindow.removeEventListener('load', handleLoad)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <Title>
      Term of <span className="blue-text">services</span>
    </Title>
    <iframe
      ref={frameRef}
      onLoad={() => console.log('onload.....')}
      border="0"
      title="Term of Services"
      // src={`${process.env.REACT_APP_TOS_PATH}${process.env.REACT_APP_TOS_VERSION}.html`}
      style={{ height: '428px', background: 'white', border: 'none', width: '100%' }}
    ></iframe>
    {/* <div
      style={{
        height: '500px',
        overflowY: 'auto',
        background: 'white',
        border: '1px solid #D6D6D6',
        borderRadius: '5px',
      }}
    ><AgreementContent /></div> */}
    <div className="text-14 bold-text">
      {!scrolledDown && <div>Please <span className="blue-text">read through</span> (and scroll) our terms to continue.</div>}
      {scrolledDown && (
        <Checkbox
          onChange={(event) => setAgreed(event.target.checked)}
        >By checking, you are confirming that you have read, understood and agree with <span className="blue-text">XCompliant's</span> term of service.</Checkbox>
      )}
    </div>
    <div className="page-button">
      <Button type="primary" disabled={!agreed} size="large" onClick={primaryAction}>
        Continue
      </Button>
    </div>
  </>
}

function Agreement ({ primaryAction }) {
  // useEffect(() => {
  //   // payment is ok OR setupCompleted
  //   if (true) {
  //     // redirect
  //   }
  // })
  // if (true) {
  //   return null
  // }

  return (
    <PageContainer
      header={<Header />}
      leftBlock={<Content primaryAction={primaryAction} />}
    />
  )
}

export default Agreement

/*
import { useCallback } from 'react'
import { useEffect, useState } from 'react'
import { useRef } from 'react'
import PageContainer from '../components/Charge/PageContainer'

export default function Agreement({ action = () => null }) {
  const frameRef = useRef()
  const [scrolledDown, setScrolledDown] = useState(false)
  const [agreed, setAgreed] = useState(false)

  const handleScroll = useCallback((event) => {
    const contentWindow = frameRef.current.contentWindow
    const scrollY = contentWindow.scrollY
    const windowHeight = contentWindow.innerHeight
    const bodyHeight = contentWindow.document.documentElement.clientHeight
    console.log(scrollY, windowHeight, bodyHeight, '*', scrollY + windowHeight, bodyHeight - 50)
    if (!scrolledDown && (scrollY + windowHeight) > (bodyHeight - 50)) {
      console.log('here')
      setScrolledDown(true)
    }
  }, [scrolledDown])

  const handleLoad = useCallback((event) => {
    const contentWindow = frameRef.current.contentWindow
    contentWindow.addEventListener('scroll', handleScroll)
  }, [handleScroll])

  useEffect(() => {
    const contentWindow = frameRef.current.contentWindow

    contentWindow.addEventListener('load', handleLoad)
    return () => {
      contentWindow.removeEventListener('scroll', handleScroll)
      contentWindow.removeEventListener('load', handleLoad)
    }
  })

  const AgreementContent = (<div className="agreement">
    <Image source="/images/accessibilty-logo.svg" />
    <div className="text-36" style={{ margin: '20px 0' }}>Term of Services</div>
    <iframe
      ref={frameRef}
      border="0"
      title="Term of Services"
      src={`${process.env.REACT_APP_TOS_PATH}${process.env.REACT_APP_TOS_VERSION}.html`}
      style={{ height: '428px', background: 'white', border: 'none', width: '100%' }}
    ></iframe>
    <div style={{ margin: '20px 0', fontWeight: '700' }} >
      {!scrolledDown && <div>Please read through (and scroll) our terms to continue.</div>}
      {scrolledDown && <div><Checkbox
        label="By checking, you are confirming that you have read, understood and agree with Accessibility Spark term of services."
        checked={agreed}
        onChange={(value) => setAgreed(value)}
      /></div>}
    </div>
    <Button primary disabled={!agreed} onClick={action}>Continue</Button>
  </div>)

  return <PageContainer
    leftBlock={AgreementContent}
  />

}
*/
