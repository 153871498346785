import { Card, Button } from 'antd'
import React from 'react'
import './PlanCard.scss'
import { ReactComponent as LimitedTime } from '../../images/limited-time.svg'
import { ReactComponent as OldPriceCross } from '../../images/old-price-cross.svg'

const PlanCard = ({
  id,
  title,
  onClick,
  buttonText = 'Start your 5-day free trial',
  price,
  oldPrice,
  priceText = '/mo',
  priceHelp,
  saveTag,
  marked,
  usedTrial,
  selected,
  period,
  features,
  // pagesLimit,
  visitorsLimit,
  pricingPlan,
  pricingPlanPeriod,
  activePlanLocalId,
  history,
}) => {

  const isDisabled = (!selected && (
    (pricingPlanPeriod === 'year' && period === 'month')
    || (pricingPlanPeriod === period && id < activePlanLocalId))
  )

  const isCurrentPlanActiveAnnualPlan = selected && period === 'year'

  const isCancelButton = selected && pricingPlanPeriod === 'month'
  const isContactUsButton = !price || isCurrentPlanActiveAnnualPlan
  const isSelectPlanButton = !isCancelButton && !isContactUsButton
  return (
    <div className={`planCard-container ${isDisabled? '__planCard-inactive' : ''}`} style={{ position: 'relative' }}>
      <Card title={title} >
        <div className="planCard">
          <div className="planCard-body">
            {oldPrice && (
              <div className="planCard-old-price-container">
                <div className="planCard-price">
                  <div className="priceValue">$ {oldPrice.toFixed(2)}</div>
                  <div className="priceText">{priceText}</div>
                </div>
                <OldPriceCross style={{ transform: 'translateY(-26px)' }} />
              </div>
            )}
            {price && (
              <div className="planCard-price-container">
                <div className="planCard-price">
                  <div className="priceValue">$ {price.toFixed(2)}</div>
                  <div className="priceText">{priceText}</div>
                </div>
                {(priceHelp || saveTag) && <div className="priceHelpWrapper">
                  {priceHelp && <div className="priceHelp">{priceHelp}</div>}
                  {saveTag && <div className="saveTag">{saveTag}</div>}
                </div>}
              </div>
            )}
            {!price && (
              <div className="planCard-price-container" style={{ marginTop: 26 }}>
                <div className="planCard-price">
                  <div className="priceValue">Contact Us</div>
                </div>
              </div>
            )}
          </div>
          <div className="planFeatures">
            <div className="text-14" style={{ flexGrow: 1 }}>
              Unlimited pages & {visitorsLimit === Infinity ? 'Over' : 'Up to'} <span className="blue-text">{visitorsLimit}</span> visitors per month
            </div>
            {features.map((feature) => <div key={feature} className="text-14">{feature}</div>)}
            {!pricingPlan && !usedTrial && <div className="text-14">5-day Free trial</div>}
          </div>
          <div className="planCard-footer">
            {isSelectPlanButton && <Button
              type="primary"
              disabled={isDisabled}
              onClick={onClick}
            >
              {usedTrial
                ? 'Select Plan'
                : buttonText}
            </Button>}
            {isContactUsButton && <Button
              disabled={isDisabled}
              type={isCurrentPlanActiveAnnualPlan ? 'default' : 'primary'}
              href={process.env.REACT_APP_CONTACT_US_URL}
              target="_blank"
            >{isCurrentPlanActiveAnnualPlan ? 'Contact Us to Cancel Plan' : 'Contact Us'}</Button>}
            {isCancelButton && <Button
              type="default"
              onClick={() => {
                history.push('/billing/cancel')
              }}
            >Cancel Plan</Button>}
          </div>
        </div>
      </Card>
      {marked && (
        <LimitedTime className="plan-mark" />
      )}
    </div>
  )
}

export default PlanCard
